import {Component, OnDestroy, OnInit} from '@angular/core';

import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-sisense-redirect',
    templateUrl: './sisense-redirect.component.html',
    styleUrls: ['./sisense-redirect.component.scss']
})
export class SisenseRedirectComponent implements OnInit, OnDestroy {
    return_to;
    subscriptions = new Subscription();
    isNewSisense = false;

    constructor(private route: ActivatedRoute,
                protected http: HttpClient) {
        if (this.route.snapshot.queryParams['sisenseTenantId']) {
            this.isNewSisense = true;
            this.return_to = this.route.snapshot.queryParams['return_to'];
        } else {
            this.return_to = this.route.snapshot.queryParams['return_to'] + '#' + route.snapshot.fragment;
        }

    }

    ngOnInit(): void {
        this.loadInInit();
    }

    loadInInit(): void {
        // call API to ask for the redirect url
        let httpParams = new HttpParams();
        httpParams = httpParams.set('return_to', this.return_to);
        httpParams = httpParams.set('is_new_sisense', this.isNewSisense);
        this.subscriptions.add(this.http.get(`${environment.api_url}sisense/authenticate`, {params: httpParams}).subscribe(urlMap => {
            const url = urlMap['redirectUrl'];

            // @ts-ignore
            window.open(url, '_self');
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
